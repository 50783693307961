<template>
  <div id="hello-map" ></div>
</template>

<script>
import AILabel from "ailabel";
export default {
  name: "labelPage",
  data() {
    return {

    }
  },

}
</script>

<style scoped>

</style>